import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiGatewayUrl } from '../../service/baseUrl';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly urlLoginDetails = 'login/details';

  constructor(private _http: HttpClient) {}
  sam: any =
    'https://api.dev.userinsights.kimclark.com/api/v2.0/';

  public fetchAuthInfo(sessionToken: string) {
    const url = this.sam + this.urlLoginDetails;
    const opt = {
      headers: {
        'Content-Type': 'application/json',
        'csd-x-auth-token': sessionToken,
      },
    };

    console.log('[fetchAuthInfo] Querying login at: ', url);
    console.log('[fetchAuthInfo] Options: ', opt);
    return this._http.get(url, opt);
  }

  public AuthExpiryCheck(timestamp: any) {
    var timestamp2: any = new Date();
    console.log('CurrentDateTimeStamp!', timestamp2);
    console.log('ExpiryDateTimestamp!', timestamp);
    var difference = timestamp - timestamp2;
    var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
    if (daysDifference == 0) {
      // not expired
      return false;
    } else {
      // expired
      return true;
    }
  }

}
